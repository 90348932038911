import React from "react";
// import SiderBar from "../components/SideBar";
import SiderBar from "../SideBar";
import Wishlistcard from "../Wishlist-card";
// import { FiSearch, FiFilter } from "react-icons/fi";
import {useState } from "react";
import Header from "../Header";
import { useQuery } from "react-query";

import * as serviceCaller from "../../Services/index";
import Filter from "../Filter";
import Loader from "../Loader";
import {Helmet} from "react-helmet";
function Wishlist() {
  let [dataApi, setDataApi] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryName,setCategoryName] = useState(null);
  let [categoryFilter, setCategoryFilter] = useState(null);

  // console.log("categoryget", categoryFilter);

  // fetching wishlistdata

  const { error,refetch } = useQuery(
    [
      "getwishlistData",
      { 
        categoryid: categoryFilter,
        includes: "purchases",
      },
    ],
    async ({ queryKey }) => {
      let { categoryid,includes } = queryKey[1];
      try {
        let res = await serviceCaller.getWishlist( categoryid,includes );
        // console.log(">>>>>>>>>>>>>>>>>>>>>>category", res.data);
        setDataApi(res.data);
      } catch (error) {
        // console.log(error);
      }
      await serviceCaller.getCategory().then((res) => setCategory(res.data))
      
    }
    
  );
  if (error) return "An error has occurred: " + error.message;

 

  return (
    <>
    <Helmet>
  <title>Wishlist - AOA Dashboard</title>
  </Helmet>
      <Header />
      <div className="container">
        <SiderBar />
        <div className="container-width">
          <div className="wishlist-header">
            <div className="">
              <h3>Wishlist</h3>
            </div>
            <div className="wishlist-searchfield">
              <div className="purchased-filter-div">
                <span className="producttab-center-filter-container">
                  <Filter 
                  category={category}
                  setCategoryFilter={setCategoryFilter}
                  setCategoryName={setCategoryName}
                  />
                </span>

               
              </div>
            </div>
          </div>

          <div className="min-container-wishlist pb-2">

          {dataApi.docs ? (
              dataApi.docs.length > 0 ? (
                dataApi.docs &&
                dataApi.docs.map((value) => (
                  <Wishlistcard value={value} refetch={refetch} dataApi={dataApi}/>
                ))
              ) : (
                <>
                  <div className="product-container pb-2 ">
                    <p className="empty-content">
                      Sorry! You haven't added any
                      <span className="orange ml-2 mr-2">
                        {categoryName} category
                      </span>
                      product to Purchased.
                    </p>
                  </div>
                </>
              )
            ) : (
              <>
                <Loader />
              </>
            )}



            {/* {dataApi.docs && dataApi.docs.length <= 0 &&
             <>
                <div className="product-container pb-2 ">
                  <p className="empty-content">Sorry! Your wishlist is empty.</p>
                </div>
              </>   
             } */}
{/* {dataApi.docs && dataApi.docs.length >= 0 ? (
              dataApi.docs &&
              dataApi.docs.map((value) => (
                <Wishlistcard
                  value={value}
                  refetch={refetch}
                 
                  dataApi={dataApi}
                />
              ))
            ) : (
              <><Loader/></>
            )} */}



          </div>
        </div>
      </div>
    </>
  );
}
export default Wishlist;
