import React from "react";
import SiderBar from "../SideBar";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Header from "../Header";
import { useKeycloak } from "@react-keycloak/web";
import { useQuery } from "react-query";

import * as serviceCaller from "../../Services/index";
import { toast } from "react-toastify";
import {Helmet} from "react-helmet";

export default function User() {
  const [keycloak, initialized] = useKeycloak();
  const [isDisabled, setIsdisabled] = useState(true);
  const [alwaysDisabled, setAlwaysDisabled] = useState(true);
  const [userApidata, setUserapidata] = useState([]);
  const [updateuserApidata, setUpdateserapidata] = useState();
  const [plantype, setPlantype] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,control ,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  // const [message, setMessage] = useState("");

  const [onchange, setOnchange] = useState(true);

  // console.log("errors", errors);
  const formSubmit = async (data) => {
    // console.log("data");
    // console.log("---data--", data);
    // update profile

    let response = await serviceCaller.updateProfile(data);

    // console.log({ response });

    if (response.status == 200) {
      refetch();
      setIsdisabled(true);

      if (response.data.message == "User Info Updated Successfully") {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }
  };

  // fetching wishlistdata
  const { isLoading, error, data, isFetching, refetch } = useQuery(
    "getProfileData",
    async () => {
      // get profile
      await serviceCaller.getProfile().then((res) => setUserapidata(res.data));

      // getSubscription
      await serviceCaller.getSubscription().then((res) => {
        // console.log(res.data);
        setPlantype(res.data);
      });
    }
  );

  // if (isLoading) return "Loading....";

  if (error) return "An error has occurred: " + error.message;

  const handleClick = () => {
    // console.log("button clicked");
    setIsdisabled(false);
  };

  // if (!userApidata.docs) {
  //   return <p>Loading... </p>;
  // }
  const handleLogout = () => {
    keycloak.logout();
  };

  let subscriptionDatedata = plantype.docs &&
    plantype.docs.active_subscriptions[0].created_on;
  console.log("subscriptionDate",subscriptionDatedata);    
    let subscriptionDate = new Date(subscriptionDatedata);
    console.log("subscriptionDate",subscriptionDate)
   let subscription_date = subscriptionDate.toLocaleDateString('en-US');
   console.log("subscription_date",subscription_date) 
    
  return (
    <>
       <Helmet>
  <title>User - AOA Dashboard</title>
  </Helmet>
      <Header />
      <div className="container">
        <SiderBar />
        <div className="container-width">
          <div className="extension-header">
            <div className="">
              <h3 className="account-title">Account details</h3>
            </div>
          </div>
          <div className="min-container">
            <div className="account-details-container pt-3">
              <div className="min-container-btn pb-3">
                <button className="logout-btn" onClick={handleLogout}>
                  logout
                </button>
              </div>
              <form
                className="account-details-innercontainer pl-5 pr-5 pt-3 pb-4"
                onSubmit={handleSubmit(formSubmit)}
              >
                <div
                //  className="account-details-titlecontainer"
                >
                  <div
                  // className="account-details-titlebuttons"
                  >
                    <button
                      type="button"
                      className="edit-btn"
                      onClick={handleClick}
                    >
                      edit
                    </button>
                  </div>
                </div>
                <div className="accountform-wrapper">
                  <div className="accountform-halfwrapper-1">
                    <label className="account_label mb-1">First Name</label>
                     <input
                      type="text"
                      className="Accountform-input"
                      name="first_name"
                      {...register("first_name", { required: true })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "first_name",
                              userApidata.docs && userApidata.docs.first_name
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("first_name");
                          setOnchange(false);
                        } else {
                          setValue("first_name", e.target.value)
                        }
                      }}
                    />

                    <br />
                    {errors.first_name && (
                      <span className="error-message">
                        Please enter First Name
                      </span>
                    )}
                  </div>
                  <div className="accountform-halfwrapper-2">
                    <label className="account_label mb-1">Last Name</label>
                  <input
                      type="text"
                      className="Accountform-input"
                      name="last_name"
                      {...register("last_name", { required: true })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "last_name",
                              userApidata.docs && userApidata.docs.last_name
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("last_name");
                          setOnchange(false);
                        } else {
                          setValue("last_name", e.target.value);
                        }
                      }}
                      // defaultValue={
                      //   userApidata.docs && userApidata.docs.first_name
                      // }
                    />

                    
                    <br />{" "}
                    {errors.last_name && (
                      <span className="error-message">
                        Please enter Last Name
                      </span>
                    )}
                  </div>
                </div>

                <div className="accountform-wrapper">
                  <div className="accountform-halfwrapper-1">
                    <label className="account_label mb-1">Email Id</label>
                    <input
                      type="email"
                      className="Accountform-input"
                      disabled={alwaysDisabled}
                      defaultValue={userApidata.docs && userApidata.docs.email}
                    />
                  </div>
                  <div className="accountform-halfwrapper-2">
                    <label className="account_label mb-1">Password</label>
                    <input
                      type="password"
                      className="Accountform-input"
                      name="password"
                      disabled={alwaysDisabled}
                    />
                  </div>
                </div>

                <div className="accountform-wrapper">
                  <div className="accountform-halfwrapper-1">
                    <label className="account_label mb-1">
                      selling Account display name
                    </label>
                    
                  

<input
                      type="text"
                      className="Accountform-input"
                      name="amazon_account_name"
                      {...register("amazon_account_name", { required: true })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "amazon_account_name",
                              userApidata.docs && userApidata.docs.amazon_account_name
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("amazon_account_name");
                          setOnchange(false);
                        } else {
                          setValue("amazon_account_name", e.target.value);
                        }
                      }}
                    />

                    <br />{" "}
                    {errors.amazon_account_name && (
                      <span className="error-message">
                        Please enter selling Account display name
                      </span>
                    )}

                  </div>
                  <div className="accountform-halfwrapper-2">
                    <label className="account_label mb-1">
                      amazon market place
                    </label>
                  
                    
                    <input
                      type="text"
                      className="Accountform-input"
                      name="amazon_marketplaces"
                      {...register("amazon_marketplaces", { required: true })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "amazon_marketplaces",
                              userApidata.docs && userApidata.docs.amazon_marketplaces
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("amazon_marketplaces");
                          setOnchange(false);
                        } else {
                          setValue("amazon_marketplaces", e.target.value);
                        }
                      }}
                    />

                    <br />{" "}
                    {errors.amazon_marketplaces && (
                      <span className="error-message">
                        Please enter Amazon Marketplace
                      </span>
                    )}
                  </div>
                </div>

                <div className="accountform-wrapper">
                  <div className="accountform-halfwrapper-1">
                    <label className="account_label mb-1">Phone No</label>
                   
                   
                   <input
                      type="text"
                      className="Accountform-input"
                      name="phone.value"
                      {...register("phone.value", { required: true,   pattern: {
                        value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                        message: "invalid Phone Please enter a Phone",
                      } })}
                      disabled={isDisabled}
                      control={control}
                      {...(onchange
                        ? {
                            ...setValue(
                              "phone.value",
                              userApidata.docs && userApidata.docs.phone.value
                            ),
                          }
                        : "")}
                      onChange={(e) => {
                        if (!e.target.value) {
                          setError("phone.value");
                          setOnchange(false);
                        } else {
                          setValue("phone.value", e.target.value);
                        }
                      }}
                      // defaultValue={
                      //   userApidata.docs && userApidata.docs.first_name
                      // }
                    />
                    <br />
                    {errors.phone && (
                      <span className="error-message">
                        {" "}
                        Please enter a Phone
                      </span>
                    )}
                  </div>
                </div>

                {/* {isDisabled ? (
                  ""
                ) : ( */}
                <button
                  // onClick={() => }
                  style={isDisabled ? { visibility: "hidden" } : {}}
                  type="submit"
                  className="save-btn"
                >
                  save
                </button>
                {/* )}  */}
                {/* </div> */}
              </form>
            </div>

            <div className="billing-overview-container mt-3 pb-4">
              <div className="account-details-innercontainer pl-5 pr-5 pt-4 pb-4">
                <h3 className="billing-title mb-3">billing overview</h3>

                <div className="billing-box  pt-2 pb-2">
                  <div className="billing-box-lists pt-2 pb-2 pl-2 pr-2">
                    <p className="billing-box-title"> subscription </p>
                    <p className="billing-box-value gold">
                      {plantype.docs &&
                        plantype.docs.active_subscriptions[0].plan_type}
                      {/* Gold , silver */}
                    </p>
                  </div>

                  <div className="billing-box-lists pt-2 pb-2 pl-2 pr-2">
                    <p className="billing-box-title">
                      subscription Started Date
                    </p>
                    <p className="billing-box-value">
                      {/* 08/04/2022 */}
                      {subscription_date ? subscription_date == "Invalid Date" ? " " : subscription_date  :" "}
                      </p>
                  </div>

                  <div className="billing-box-lists pt-2 pb-2 pl-2 pr-2">
                    <p className="billing-box-title">billing e-Mails </p>
                    <p className="billing-box-value">
                      {/* abc@gmail.com */}
                      {userApidata.docs && userApidata.docs.email}
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
