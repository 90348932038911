const axios = require("axios");
const { useQuery } = require("react-query");
// const { jsonToQueryString } = require("../utils/jsonToQueryString");
const keycloak = require("../keycloak");

const baseURL = process.env.REACT_APP_BASEURL;
const baseURLV1 = process.env.REACT_APP_BASEURL_V1;

// all deal
export async function getAllDeals({
  page = 1,
page_size = 20,
  deal_type = "all",
  name,
  sortBy = "-_id",
  category_id = "",
  plan_type,
  list_id,
  includes,
}) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  // console.log("___MAXK>><<", sortBy)
  // console.log("setvi cat",category_id)

  return await axios.get(`${baseURL}deals`, {
    headers: headers,
    params: {
      name,
      page,
      page_size,
      deal_type,
      sort: sortBy,
      category_id,
      plan_type,
      list_id,
      includes,
    },
  });
}

// today deal
export async function todayDeals({
  page = 1,
  page_size = 20,
  deal_type = "today",
  sortBy = "-_id",
  category_id = "",
  name,
  plan_type,
  list_id,
  includes,
}) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  // console.log("___MAXK>><<", sortBy)
  // console.log("setvi cat",category_id)

  return await axios.get(`${baseURL}deals`, {
    headers: headers,
    params: {
      page,
      page_size,
      deal_type,
      sort: sortBy,
      category_id,
      name,
      plan_type,
      list_id,
      includes,
    },
  });
}


export async function getTodayDeals({
  
  page_size = 20,
  page = 1,
  deal_type = "today",
  // sortBy = "_id",
  // category_id = "",
  // name,
  // plan_type,
  // list_id,
  // includes,
}) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  // console.log("___MAXK>><<", sortBy)
  // console.log("setvi cat",category_id)

  return await axios.get(`${baseURL}deals`, {
    headers: headers,
    params: {
      page,
      page_size,
      deal_type,
      // sort: sortBy,
      // category_id,
      // name,
      // plan_type,
      // list_id,
      // includes,
    },
  });
}
// get likes

export async function getLikes(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.post(`${baseURL}deals/${value._id}/likes`, value, {
    headers: headers,
  });
}

// get dislikes

export async function getDislikes(value) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return await axios.post(`${baseURL}deals/${value._id}/dislikes`, value, {
    headers: headers,
  });
}







// add and remove wishlist
export function addWishlist(
  value,
  filter = {},
  page = 1,
  page_size = 20,
  deal_type = "all",
  sort = ["-_id"]
) {
  // console.log(value)
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURL}deals/${value._id}/favorites`, value, {
    headers: headers,
    params: {
      // ...filter,
      page,
      page_size,
      deal_type,
      // sort,
    },
  });
}

// add and remove purchaselist
export function addPurchase(
  value,
  filter = {},
  page = 1,
  page_size = 20,
  deal_type = "all",
  sort = ["-_id"]
) {
  // console.log(value)
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.post(`${baseURL}deals/${value._id}/purchases`, value, {
    headers: headers,
    params: {
      // ...filter,
      page,
      page_size,
      deal_type,
      // sort,
    },
  });
}

// display wishlist data

export function getWishlist(
  // page = 1,
  // page_size = 20,
  // deal_type = "all",
  // sortBy = "_id",
  // category_id = "",
  categoryid,
  includes
  // ...filters
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}deals/favorites`, {
    headers: headers,
    params: {
      categoryid,
      includes
      // ...filters,
      // page,
      // page_size,
      // deal_type,
      // sort: sortBy,
      // category_id,
    },
  });
}

// get purchase data
export function getPurchased(
  categoryid = " "
  // filter = {},
  // page = 1,
  // page_size = 20,
  // deal_type = "all",
  // sort = ["-_id"],
  // category_id = ""

) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}deals/purchases`, {
    headers: headers,
    params: {
      // ...filter,
      // page,
      // category_id,
      categoryid,
      // page_size,
      // deal_type,
      // sort,
    },
  });
}

// get Categories data
export function getCategory(
  // filter = {},
  // category_id=category_id,
  page = 1,
  page_size = 20,
  deal_type = "all"
  // sort = ["-_id"]
  // id=[_id],
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}categories`, {
    headers: headers,
    params: {
      // ...filter,
      // id:id,
      // id:category_id,
      page,
      page_size,
      deal_type,
      // sort,
    },
  });
}

// get profile data
export function getProfile() {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}users/profile`, {
    headers: headers,
  });
}

// update profile data
export function updateProfile(data) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.put(`${baseURL}users/profile`, data, {
    headers: headers,
  });
}

// get subscription  data
export function getSubscription(
  filter = {},
  // page = 1,
  // page_size = 20,
  deal_type = "all"
  // sort = ["-_id"]
) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + keycloak.default.token,
  };

  return axios.get(`${baseURL}users/subscription`, {
    headers: headers,
    params: {
      ...filter,
      // page,
      // page_size,
      deal_type,
      // sort,
    },
  });
}
