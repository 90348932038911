import React from "react";
// import productimage from '../assets/img/product.jpg'
import {
  AiOutlineShoppingCart,
  AiOutlineHeart,
  AiOutlineLike,
  AiOutlineDislike,
  AiOutlineAmazon,
  AiFillCheckCircle,
  AiFillHeart,
  AiFillLike,
  AiFillDislike,
} from "react-icons/ai";
import { BsFillCartCheckFill } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";
import { MdUpdate } from "react-icons/md";
import axios from "axios";
import { useState } from "react";
import Keepa from "../Keepa";
import { toast } from "react-toastify";
import * as serviceCaller from "../../Services/index";
import moment from "moment";

function Productcard({ value, render, refetch, activeTab }) {
  // console.log("----productCard---", value);

  const [loading, setLoading] = useState(false);

  // add wishlist

  const handleAddWishlist = async () => {
    // console.log("...wishlist", value);
    let res = await serviceCaller.addWishlist(value);

    if (res.status == 200) {
      refetch();
      setLoading(false);
      if (res.data.message === "Removed Favorites Successfully") {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
    }
  };

  // add purchase
  const handlePurchased = async () => {
    let res = await serviceCaller.addPurchase(value);

    if (res.status == 200) {
      refetch();
      setLoading(false);
      if (res.data.message === "Removed Purchases Successfully") {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
    }
  };

  

  let [disablebutton, setDisablebutton] = useState(false);
  // add likes
  const handleLike = async () => {
    let res = await serviceCaller.getLikes(value);
    // console.log("likes", res);

    if (res.status == 200) {
      refetch();
      setLoading(false);
      if (res.data.message === "disLiked Successfully") {
        toast.error(res.data.message);
        // toast.error(" ")
      } else {
        toast.success(res.data.message);
      }
    }
   
    setDisablebutton(true);
  };

  //add dislikes
  const handledisLike = async () => {
    let res = await serviceCaller.getDislikes(value);
    // console.log("likes", res);

    if (res.status == 200) {
      refetch();
      setLoading(false);
      if (res.data.message === "Liked Successfully") {
        toast.error(res.data.message);
        // toast.error(" ")
      } else {
        toast.success(res.data.message);
      }
    }
   
    setDisablebutton(true);
  };

  
  const [oneDay, setOneDay] = useState({});
  const [month, setMonth] = useState({});
  const [week, setWeek] = useState({});
  // const [sixtyDay, setSixtyDay] = useState({});
  const [visible, setVisible] = useState(false);
  // const [asin, setAsin] = useState(null);

  const [day, setDay] = useState(1);
  const keepa = async (data) => {
    let baseUrl = `https://api.keepa.com/product`;

    let dayResponse = await axios.get(baseUrl, {
      params: {
        key: "8jbces27j86mqkijcfeb1vtrgh75eh21r0f0t6dbf16mf1ok2jd7vhmnn4e9nbrt",
        domain: 1,
        asin: data,
        days: 1,
      },
    });

    let monthResponse = await axios.get(baseUrl, {
      params: {
        key: "8jbces27j86mqkijcfeb1vtrgh75eh21r0f0t6dbf16mf1ok2jd7vhmnn4e9nbrt",
        domain: 1,
        asin: data,
        days: 30,
      },
    });

    let weekResponse = await axios.get(baseUrl, {
      params: {
        key: "8jbces27j86mqkijcfeb1vtrgh75eh21r0f0t6dbf16mf1ok2jd7vhmnn4e9nbrt",
        domain: 1,
        asin: data,
        days: 7,
      },
    });
    // let SixtyDayResponse = await axios.get(baseUrl, {
    //   params: {
    //     key: "8jbces27j86mqkijcfeb1vtrgh75eh21r0f0t6dbf16mf1ok2jd7vhmnn4e9nbrt",
    //     domain: 1,
    //     asin: asin,
    //     days: 60,
    //   },
    // });
    setOneDay(dayResponse.data);
    setMonth(monthResponse.data);
    setWeek(weekResponse.data);
    // setSixtyDay(SixtyDayResponse.data);

    // const tracking = dayResponse.data.products[0].trackingSince;

    // const date = moment
    //   .utc((tracking + 21564000) * 60000)
    //   .set({ second: 0, millisecond: 0 })
    //   .toDate();

    // console.log("dates", date);
  };
  // useEffect(() => {
  //   keepa();
  // }, [asin]);

  const handleGraph = (e) => {
    // console.log(e.target.dataset.asin);
    setVisible((prevState) => !prevState);
    keepa(e.target.dataset.asin);
    // setAsin(e.target.dataset.asin);
  };

  const past24hrs = moment.utc().subtract(24, "hours").valueOf();

  // console.log("past24hrs", past24hrs);

  const past72hrs =moment.utc().subtract(72, "hours").valueOf() ;

  // console.log("past72hrs=", past72hrs);

  // console.log("3days before = ",new Date(past72hrs).toLocaleDateString('en-US'));

  return (
    <>
      <div className="productcard">
        <div className="product-container">
          <div className="product-details mt-2 pt-2 pb-2 pr-2 pl-2">
            <div className="product-image-title">
              <div className="product-image">
                <img
                  src={value.image}
                  alt="productimage"
                  className="productimage"
                />
              </div>

              <div className="product-heading">
                <h4 className="product-title pt-1">{value.name}</h4>
                <h5 className="category-title pt-1">
                  {/* Health and Household */}
                  {value.category ? value.category.name : "Uncategorized"}
                </h5>
                <div className="product-buttons mt-2">
                  {new Date(past24hrs) < new Date(value.created_on) ? (
                    activeTab === "todaydeal" ? (
                      ""
                    ) : (
                      // ""
                      <button className="new-btn">
                        <span className="new-btn-span">
                          <AiFillCheckCircle />
                        </span>
                        <span className="new-btn-span">New</span>
                      </button>
                    )
                  ) : (
                    ""
                  )}

                  {new Date(past72hrs) < new Date(value.updated_on) ? (
                    activeTab === "todaydeal" ? (
                      ""
                    ) : (
                      <button className="update-btn">
                        <span className="update-btn-span">
                          <MdUpdate />
                        </span>
                        <span>update</span>
                      </button>
                    )
                  ) : (
                    ""
                  )}


                  
                </div>
              </div>
            </div>
            <div className="addtocart">
              <button className="cart-btn" onClick={handlePurchased}>
                {value.is_purchases ? (
                  <BsFillCartCheckFill color="#ff9900" />
                ) : (
                  <AiOutlineShoppingCart color="#ff9900" />
                )}
              </button>

              <button className=" heart-btn" onClick={handleAddWishlist}>
                {value.is_favorites ? (
                  <AiFillHeart color="#ff9900" />
                ) : (
                  <AiOutlineHeart color="#ff9900" />
                )}
              </button>

              <button
                className="like-btn"
                onClick={handleLike}
                disabled={value.is_likes || value.is_dislikes}
              >
                {value.is_likes ? (
                  <AiFillLike color="#01ac4e" />
                ) : (
                  <AiOutlineLike color="#01ac4e" />
                )}
              </button>
              <button
                className="dislike-btn"
                onClick={handledisLike}
                disabled={value.is_dislikes || value.is_likes}
              >
                {value.is_dislikes ? (
                  <AiFillDislike color="#ff5e5e" />
                ) : (
                  <AiOutlineDislike color="#ff5e5e" />
                )}{" "}
              </button>
            </div>
          </div>

          <div className="product-info mt-1">
            <div className="product-table-info mt-2 pl-4 mr-3">
              <div className="product-table pt-2">
                <div className="product-table-list-1">
                  <h4 className="product-table-title"> Store Name </h4>
                  <h5 className="product-table-value "> {value.store_name}</h5>

                  <h4 className="product-table-title">Net Profit </h4>
                  <h5 className="product-table-value">
                    ${Number(value.net_profit)}
                  </h5>

                  <h4 className="product-table-title">EST Monthly Sale </h4>
                  <h5 className="product-table-value">
                    {value.estimated_monthly_sales}
                  </h5>
                </div>

                <div className="product-table-list-2">
                  <h4 className="product-table-title"> Store Price </h4>
                  <h5 className="product-table-value">
                    ${Number(value.store_price)}
                  </h5>

                  <h4 className="product-table-title">BSR% </h4>
                  <h5 className="product-table-value"> {Number(value.bsr)}</h5>

                  <h4 className="product-table-title">ASIN </h4>
                  <h5 className="product-table-value"> {value.asin}</h5>
                </div>

                <div className="product-table-list-3">
                  <h4 className="product-table-title"> Amazon Price </h4>
                  <h5 className="product-table-value">
                    ${Number(value.amazon_price)}
                  </h5>
                  <h4 className="product-table-title">FBA Seller </h4>
                  <h5 className="product-table-value">
                    {Number(value.fba_seller_count)}{" "}
                  </h5>
                  <h4 className="product-table-title">BSR Rank </h4>
                  <h5 className="product-table-value">
                    {Number(value.ninety_days_rank)}
                  </h5>
                </div>
                <div className="product-table-list-4">
                  <h4 className="product-table-title"> ROI</h4>
                  <h5 className="product-table-value"> {Number(value.roi)}%</h5>
                  <h4 className="product-table-title">FBM Seller </h4>
                  <h5 className="product-table-value">
                    {Number(value.fbm_seller_count)}
                  </h5>

                  <h4 className="product-table-title">UPC/EAN </h4>
                  <h5 className="product-table-value">
                    {Number(value.upc_ean)}
                  </h5>
                </div>
              </div>
              <hr className="rulerline" />
              <h4 className="product-offer-info pb-2 pt-1">
                Don't forget to make use of below offers!
              </h4>
              <div className="product-offer-table">
                <div className="product-offer-table-list-1">
                  <h4 className="product-offer-info-title"> Notes </h4>
                  <h5 className="product-offer-info-value pr-3">
                    {value.notes}
                  </h5>
                  <h4 className="product-offer-info-title">Promotion Code </h4>
                  <h5 className="product-offer-info-value pr-3">
                    {value.promotion_code}
                  </h5>
                </div>
                <div className="product-offer-table-list-2">
                  <h4 className="product-offer-info-title"> Shipping Notes </h4>
                  <h5 className="product-offer-info-value pr-3">
                    {value.shipping_notes}
                  </h5>
                  <h4 className="product-offer-info-title">Hazmat </h4>
                  <h5 className="product-offer-info-value pr-3">
                    {(value.is_hazmat = true ? "Yes" : "No")}
                  </h5>
                </div>
              </div>
            </div>
            <div className="calculator pt-2">
              <div className="calculator-container">
                <h3 className="calculator-title pt-2 pb-2">calculator </h3>
                <div
                  className="extension-container"
                  data-asin="B076TCWBPL"
                  data-amazon={value.amazon_price && Number(value.amazon_price)}
                  data-store={value.store_price && Number(value.store_price)}
                  data-add="1.20"
                  data-render={render}
                >
                  <div className="loading-div">
                    <div id="loading"></div>
                    <h2 className="loading-title text-center">Loading...</h2>

                    <p className="loading-para text-center">
                      Please wait while the data loads hanging
                    </p>
                  </div>
                </div>
              </div>

              <div className="asin-checker" data-asin="B074JT3698">
                {/* <p className="message ">
                  <span>
                    <BsFillCheckCircleFill />
                  </span>
                  Yeh!!! You are eligible to sell!!
                </p> */}
                <p className="message">Loading....</p>
              </div>
            </div>
          </div>

          <div className="keppagraph-buttons">
            <button
              className="graph-btn mt-1 mb-1"
              onClick={handleGraph}
              data-asin="B076TCWBPL"
            >
              view graph
            </button>
            <div className="calculator-buttons ">
              <a
                className="amazon-btn"
                href={value.amazon_product_url}
                target="_blank"
              >
                <AiOutlineAmazon /> amazon
              </a>
              <a
                className="store-btn "
                href={value.store_product_url}
                target="_blank"
              >
                <FaShoppingCart /> store
              </a>
            </div>
          </div>
          {visible &&
            (!oneDay.products ? (
              "Loading..."
            ) : (
              <div>
                <div>
                  <Keepa
                    oneDay={oneDay}
                    month={month}
                    day={day}
                    week={week}
                    // sixtyDay={sixtyDay}
                  />
                </div>
                <div className="row">
                  <a onClick={() => setDay(1)} className="ml-3">
                    1d
                  </a>
                  <a onClick={() => setDay(7)} className="ml-3">
                    7d
                  </a>
                  <a onClick={() => setDay(30)} className="ml-3">
                    30d
                  </a>
                  {/* <a onClick={() => setDay(60)} className="ml-3">
                60d
              </a> */}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
export default Productcard;
