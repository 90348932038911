import React from "react";
import Home from "./components/Home";
import User from "./components/User";
import Extension from "./components/Extension";
import Learnmore from "./components/Learnmore";
import Wishlist from "./components/Wishlist";
import Purchased from "./components/Purchased";

// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Pricing from "./components/Pricing";
import { useKeycloak } from "@react-keycloak/web";
import { ToastContainer } from "react-toastify";
import ErrorPage from "./components/404";

function App() {
  const [keycloak, initialized] = useKeycloak();
  if (initialized && keycloak.authenticated) {
    // if (!keycloak.realmAccess.roles.some((e) => e == "app-admin")) {
    return (
      <>
        {/* <Router> */}
     <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/purchased" element={<Purchased />} />
            <Route path="/pricing" element={<Pricing />} />

            <Route path="/user" element={<User />} />
            <Route path="/extension" element={<Extension />} />

            <Route path="/learnmore" element={<Learnmore />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        {/* </Router> */}
    </BrowserRouter>
        <ToastContainer
          position="bottom-left"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
        />
      </>
    );
    // }
  } else {
    keycloak.login();
  }
}

export default App;
