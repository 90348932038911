import React from "react";
import CanvasJSReact from "../../assets/utilis/canvasjs.react";
import moment from "moment";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Day({ data }) {
  console.log("data", data);
  let amazon = data.products[0].csv[0];
  let newData = data.products[0].csv[1];
  let used = data.products[0].csv[2];
  let salesRank = data.products[0].csv[3];

  // console.log("salesRank", salesRank);

  let newDataPriceHistory = [];
  let amazonPriceHistory = [];
  let usedPriceHistory = [];
  let salesRankPriceHistory = [];

  let refNewDataPriceHistory = newData
    .map((kpt, i) => {
      if (i % 2 === 0) {
        return {
          time: moment
            .utc((kpt + 21564000) * 60000)
            .set({ second: 0, millisecond: 0 })
            .toDate(),
          price: newData[i + 1],
        };
      }
    })
    .filter((e) => e);

  let refAmazonPriceHistory = amazon
    .map((kpt, i) => {
      if (i % 2 === 0) {
        return {
          time: moment
            .utc((kpt + 21564000) * 60000)
            .set({ second: 0, millisecond: 0 })
            .toDate(),
          price: amazon[i + 1],
        };
      }
    })
    .filter((e) => e);

  let refUsedPriceHistory = used
    .map((kpt, i) => {
      if (i % 2 === 0) {
        return {
          time: moment
            .utc((kpt + 21564000) * 60000)
            .set({ second: 0, millisecond: 0 })
            .toDate(),
          price: used[i + 1],
        };
      }
    })
    .filter((e) => e);

  let refSalesRankPriceHistory = salesRank
    .map((kpt, i) => {
      if (i % 2 === 0) {
        return {
          time: moment
            .utc((kpt + 21564000) * 60000)
            .set({ second: 0, millisecond: 0 })
            .toDate(),
          price: salesRank[i + 1],
        };
      }
    })
    .filter((e) => e);

  //   console.log("refNewDataPriceHistory", refNewDataPriceHistory);
  // console.log("refAmazonPriceHistory", refAmazonPriceHistory);
  // console.log("refUsedPriceHistory", refUsedPriceHistory);
  // console.log("refSalesRankPriceHistory", refSalesRankPriceHistory);

  let previousDay = moment().subtract(1, "days").toDate();

  // console.log(previousDay);

  let newDataCurrentPrice;
  let amazonCurrrentPrice;
  let usedCurrentPrice;
  let salesRankCurrentPrice;

  if (refNewDataPriceHistory[0].time <= previousDay) {
    newDataCurrentPrice = refNewDataPriceHistory[0].price;
  }

  if (refAmazonPriceHistory[0].time <= previousDay) {
    amazonCurrrentPrice = refAmazonPriceHistory[0].price;
  }

  if (refUsedPriceHistory[0].time <= previousDay) {
    usedCurrentPrice = refUsedPriceHistory[0].price;
  }

  if (refSalesRankPriceHistory[0].time <= previousDay) {
    salesRankCurrentPrice = refSalesRankPriceHistory[0].price;
  }

  newDataPriceHistory = Array.from({ length: 1440 }).map((e, i) => {
    let time = moment()
      .set({ second: 0, millisecond: 0 })
      .subtract(24, "hours")
      .add(1 + i, "minutes")
      .toDate();

    // console.log("time", time)
    let y = 0;
    let keepaTimeIndex = refNewDataPriceHistory.findIndex((kpt) =>
      moment(kpt.time).isSame(time)
    );
    // console.log({ keepaTimeIndex });
    if (keepaTimeIndex >= 0) {
      y = refNewDataPriceHistory[keepaTimeIndex].price;
      newDataCurrentPrice = y;
    } else {
      y = newDataCurrentPrice;
    }
    y = Number((y * 0.01).toFixed(2));
    y = y == -0.01 ? null : y;
    return {
      x: time,
      y,
    };
  });

  amazonPriceHistory = Array.from({ length: 1440 }).map((e, i) => {
    let time = moment()
      .set({ second: 0, millisecond: 0 })
      .subtract(24, "hours")
      .add(1 + i, "minutes")
      .toDate();

    // console.log("time", time)
    let y = 0;
    let keepaTimeIndex = refAmazonPriceHistory.findIndex((kpt) =>
      moment(kpt.time).isSame(time)
    );
    // console.log({ keepaTimeIndex });
    if (keepaTimeIndex >= 0) {
      y = refAmazonPriceHistory[keepaTimeIndex].price;
      amazonCurrrentPrice = y;
    } else {
      y = amazonCurrrentPrice;
    }
    y = Number((y * 0.01).toFixed(2));
    y = y == -0.01 ? null : y;
    return {
      x: time,
      y,
    };
  });

  usedPriceHistory = Array.from({ length: 1440 }).map((e, i) => {
    let time = moment()
      .set({ second: 0, millisecond: 0 })
      .subtract(24, "hours")
      .add(1 + i, "minutes")
      .toDate();
    // console.log("time", time)
    let y = 0;
    let keepaTimeIndex = refUsedPriceHistory.findIndex((kpt) =>
      moment(kpt.time).isSame(time)
    );
    // console.log({ keepaTimeIndex });
    if (keepaTimeIndex >= 0) {
      y = refUsedPriceHistory[keepaTimeIndex].price;
      usedCurrentPrice = y;
    } else {
      y = usedCurrentPrice;
    }
    y = Number((y * 0.01).toFixed(2));
    y = y == -0.01 ? null : y;
    return {
      x: time,
      y,
    };
  });

  salesRankPriceHistory = Array.from({ length: 1440 }).map((e, i) => {
    let time = moment()
      .set({ second: 0, millisecond: 0 })
      .subtract(24, "hours")
      .add(1 + i, "minutes")
      .toDate();
    // console.log("time", time)
    let y = 0;
    let keepaTimeIndex = refSalesRankPriceHistory.findIndex((kpt) =>
      moment(kpt.time).isSame(time)
    );
    // console.log({ keepaTimeIndex });
    if (keepaTimeIndex >= 0) {
      y = refSalesRankPriceHistory[keepaTimeIndex].price;
      salesRankCurrentPrice = y;
    } else {
      y = salesRankCurrentPrice;
    }
    // y = Number(y * 0.01);
    y = y == -1 ? null : y;
    return {
      x: time,
      y,
    };
  });

  const options = {
    theme: "light2",
    animationEnabled: true,
    exportEnabled: true,
    title: {
      text: "Keepa Chart",
    },
    axisX: {
      crosshair: {
        enabled: true,
        lineDashType: "solid",
        opacity: 0.8,
      },
    },
    axisY: {
      title: "Price",
      includeZero: false,
    },
    axisY2: {
      title: "Rank",
    },
    toolTip: {
      shared: true,
      contentFormatter: function (e) {
        var content = " ";
        for (var i = 0; i < e.entries.length; i++) {
          content +=
            e.entries[i].dataSeries.name +
            " : " +
            "<strong>" +
            e.entries[i].dataPoint.y +
            "</strong>";
          content += "<br/>";
        }
        return content;
      },
    },
    data: [
      {
        name: "Amazon",
        type: "area",
        lineColor: "#ff9900",
        markerColor: "#ff9900",
        color: "rgb(255,231,208)",
        // showInLegend: true,
        // axisYType: "secondary",
        connectNullData: true,
        xValueFormatString: "DD MMM YYYY hh:mm",
        dataPoints: amazonPriceHistory,
        nullDataLineDashType: "shortDot",
      },
      {
        name: "New",
        type: "stepLine",
        lineColor: "#88d",
        markerColor: "#88d",
        xValueFormatString: "DD MMM YYYY hh:mm",
        markerSize: 3,
        // showInLegend: true,
        connectNullData: true,
        dataPoints: newDataPriceHistory,
        nullDataLineDashType: "shortDot",
      },

      {
        name: "Used",
        type: "stepLine",
        lineColor: "#444",
        markerColor: "#444",
        // showInLegend: true,
        // axisYType: "secondary",
        connectNullData: true,
        xValueFormatString: "DD MMM YYYY hh:mm",
        dataPoints: usedPriceHistory,
        nullDataLineDashType: "shortDot",
      },
      {
        name: "Sales Rank",
        type: "stepLine",
        lineColor: "#8DB98D",
        markerColor: "#8DB98D",
        // showInLegend: true,
        axisYType: "secondary",
        connectNullData: true,
        xValueFormatString: "DD MMM YYYY hh:mm",
        dataPoints: salesRankPriceHistory,
        nullDataLineDashType: "shortDot",
      },
    ],
  };

  if (!data.products) {
    return <>Loading...</>;
  }

  return (
    <>
      <div style={{ margin: "30px" }}>
        <CanvasJSChart options={options} />
      </div>
    </>
  );
}

export default Day;
