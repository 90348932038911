import React from "react";
import SiderBar from "../SideBar";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import Header from "../Header";
import {Helmet} from "react-helmet";
function Learnmore() {
  return (
    <>
     <Helmet>
  <title>Learnmore - AOA Dashboard</title>
  </Helmet>
      <Header />

      <div className="container">
        <SiderBar />
        <div className="container-width">
          <div className="extension-header">
            <div className="">
              <h3 className="blog-heading  pr-2 pl-2">blog</h3>
            </div>
          </div>
          <div className="min-container">
            <div className="learnmore-container pt-4 pr-6 pl-6 pb-4">
              <div className="blog-heading-div">
                <p className="blog-para-heading text-center">
                  If you are a new seller or an experienced seller, we help you
                  to become successful on
                  <br /> your Amazon/ecommerce business journey with our tips.
                </p>
              </div>


              <div className="learnmore-wrapper">
                <div className="learnmore-image-div">
                  <img src="/img/blog-5.jpg" className="learnmore-image" />
                </div>
                <div className="learnmore-wrapper-text">
                  <h3 className="extension-title">
                  NEW EXCLUSIVE FEATURE OF AMZ ONLINE ARBITRAGE
                  </h3>

                  <p className="learnmore-paragraph">
                  One great piece of news for all the Amazon Sellers! Your dearest Amz Online Arbitrage has introduced an amazing feature for you!! Eager to know what it is?
Now, you need not check your inbox to get the deals now!! Just go to our NEW DASHBOARD and get your favorite deals in one view.Wanna know more about it? Let's dig deeper.
                  </p>

                  <a
                    className="learnmore-btn"
                    target="_blank"
                    href="https://amzonlinearbitrage.com/blog/new-exclusive-feature-of-amz-online-arbitrage"
                  >
                    <span className="learnmore-text">read more</span>

                    <span className="learnmore-icon">
                      <HiOutlineArrowNarrowRight />
                    </span>
                  </a>
                </div>
              </div>


              <div className="learnmore-wrapper">
                <div className="learnmore-image-div">
                  <img src="/img/blog-1.jpg" className="learnmore-image" />
                </div>
                <div className="learnmore-wrapper-text">
                  <h3 className="extension-title">
                    AMZ ONLINE ARBITRAGE HOW TO USE IT TO EARN PROFITS ?
                  </h3>

                  <p className="learnmore-paragraph">
                    Hey Amazon Sellers! Have you heard about online arbitrage on
                    Amazon? Do you know what wonders it could make to your
                    Amazon Business? Let's dive deep to know more about online
                    arbitrage and Amz Online Arbitrage. First, let's have a
                    glance at online arbitrage on Amazon.
                  </p>

                  <a
                    className="learnmore-btn"
                    target="_blank"
                    href="https://amzonlinearbitrage.com/blog/amz-online-arbitrage-how-to-use-it-to-earn-profits"
                  >
                    <span className="learnmore-text">read more</span>

                    <span className="learnmore-icon">
                      <HiOutlineArrowNarrowRight />
                    </span>
                  </a>
                </div>
              </div>

              <div className="learnmore-wrapper">
                <div className="learnmore-image-div">
                  <img src="/img/blog-2.jpg" className="learnmore-image" />
                </div>
                <div className="learnmore-wrapper-text">
                  <h3 className="extension-title">
                    HOW AMAZON ONLINE ARBITRAGE WORKS ? A DETAILED EXPLANATION
                  </h3>

                  <p className="learnmore-paragraph">
                    If you are an Amazon Seller your main goal will be earning
                    profits right? In that case, I have an idea for you. When
                    you do this, you can fetch extraordinary profits in just a
                    few days. Sounds interesting right! Let's go deeper.
                    Arbitrage on Amazon is a very powerful strategy that will
                    drive you good profits rapidly. But you have to spend enough
                    time to choose the product and analyze it.{" "}
                  </p>

                  <a
                    className="learnmore-btn"
                    target="_blank"
                    href="https://amzonlinearbitrage.com/blog/how-amazon-online-arbitrage-works--a-detailed-explanation"
                  >
                    <span className="learnmore-text">read more</span>

                    <span className="learnmore-icon">
                      <HiOutlineArrowNarrowRight />
                    </span>
                  </a>
                </div>
              </div>
              <div className="learnmore-wrapper">
                <div className="learnmore-image-div">
                  <img src="/img/blog-3.jpg" className="learnmore-image" />
                </div>
                <div className="learnmore-wrapper-text">
                  <h3 className="extension-title">
                    WANT TO BECOME AN AMAZON FBA SELLER?
                  </h3>

                  <p className="learnmore-paragraph">
                    If you plan to sell on Amazon, chances are you've come to
                    know that it's not that easy. But if you utilize Amazon FBA
                    Online Arbitrage, it will become quite easier. That's great
                    right, to make you understand we break down into simpler
                    steps that will make you turn into a successful Amazon FBA
                    Online Arbitrage seller.{" "}
                  </p>

                  <a
                    className="learnmore-btn"
                    target="_blank"
                    href="https://amzonlinearbitrage.com/blog/want-to-become-an-amazon-fba-seller"
                  >
                    <span className="learnmore-text">read more</span>

                    <span className="learnmore-icon">
                      <HiOutlineArrowNarrowRight />
                    </span>
                  </a>
                </div>
              </div>
              <div className="learnmore-wrapper">
                <div className="learnmore-image-div">
                  <img src="/img/blog-4.jpg" className="learnmore-image" />
                </div>
                <div className="learnmore-wrapper-text">
                  <h3 className="extension-title">
                    SELLING ONLINE? UNDERSTAND YOUR BUYER'S JOURNEY
                  </h3>

                  <p className="learnmore-paragraph">
                    If you want to get visibility and increase your sales, you
                    need to understand your buyer's journey. With consumers
                    currently making half of their purchases online, it's
                    important to ensure that your e-commerce site addresses the
                    buyer's journey. Some start their search to find out the
                    solution for their problem, some search for the products
                    straight away
                  </p>

                  <a
                    className="learnmore-btn"
                    target="_blank"
                    href="https://amzonlinearbitrage.com/blog/selling-online-understand-your-buyers-journey"
                  >
                    <span className="learnmore-text">read more</span>

                    <span className="learnmore-icon">
                      <HiOutlineArrowNarrowRight />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Learnmore;
