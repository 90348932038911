import React from "react";
import SiderBar from "../SideBar";

import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import Header from "../Header";
import {Helmet} from "react-helmet";
function Extension() {
  return (
    <>
      <Helmet>
  <title>Extension - AOA Dashboard</title>
  </Helmet>
      <Header />
      <div className="container">
        <SiderBar />
        <div className="container-width">
        <div className="extension-header">
            <div className="">
            <h3 className="extension-heading ">
            
            Extensions For Amazon Sellers
          </h3>

          
              </div>
              </div>
          <div className="min-container">
          <h4 className="extension-subheading pt-4  pl-4 pr-4 text-center">
            To cut down the struggles and save the time of every Amazon
            Seller, we have introduced two amazing extensions. Just add them
            to your chrome.
             {/* and witness the wonders.{" "} */}
          </h4>
            <div className="pt-2 pb-2">
              <div className="extension-wrapper-1">
                <div className="extension-image-wrapper">
                  <img src="/img/extension-2.jpg" className="blogoneImage" />
                </div>
                <div className="extention-wrapper-text">
                  <h3 className="extension-title">
                    {" "}
                    Amz Online Arbitrage Multi-Tool
                  </h3>

                  <p className="extension-paragraph">
                    Spending time searching for product details on Amazon? No
                    need to scroll down! We'll bring it to the top!! Our
                    Multi-Tool extension will give you all the product details
                    along with the FBA calculator so that you can estimate your
                    Average Net Profit and ROI on the product page itself.
                  </p>

                  <a className="extension-btn" href="https://chrome.google.com/webstore/detail/amz-online-arbitrage-mult/nfkdfglieafjmhpiiccobhehliaaolle">
                    <span className="extension-text">Install Now</span>

                    <span className="extension-icon">
                      <HiOutlineArrowNarrowRight />
                    </span>
                  </a>
                </div>
              </div>

              <div className="extension-wrapper-1">
                <div className="extention-wrapper-text">
                  <h3 className="extension-title">
                    Amz Online Arbitrage ASIN Checker
                  </h3>

                  <p className="extension-paragraph">
                    Restrictions and Hazmat are the biggest threats! Find your
                    product's restriction status and hazmat on the product page
                    itself with the help of Amz Online Arbitrage ASIN Checker
                    and save your precious minutes.
                  </p>

                  <a className="extension-btn" href="https://chrome.google.com/webstore/detail/amz-online-arbitrage-asin/eepoploaohjnlohelbnombfccjfnhhen">
                    <span className="extension-text">Install Now</span>

                    <span className="extension-icon">
                      <HiOutlineArrowNarrowRight />
                    </span>
                  </a>
                </div>
                <div className="extension-image-wrapper">
                  <img src="/img/extension-1.jpg" className="blogoneImage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Extension;
