import React from "react";
import SiderBar from "../SideBar";
import Wishlistcard from "../Wishlist-card";

import { useState } from "react";

import { FiSearch, FiFilter } from "react-icons/fi";
import Header from "../Header";
import { useQuery } from "react-query";

import * as serviceCaller from "../../Services/index";
import Filter from "../Filter";
import Loader from "../Loader";
import {Helmet} from "react-helmet";
function Purchased() {
  let [purchasedapiData, setPurchasedapiData] = useState([]);
  const [category, setCategory] = useState([]);
  const [categoryName,setCategoryName] = useState(null);
  let [categoryFilter, setCategoryFilter] = useState(null);

  // console.log("categorygetpurchased", categoryFilter);




  // fetching wishlistdata
  const { isLoading, error, data, isFetching,refetch } = useQuery(
    [
      "getPurchasedData",
      {
        categoryid: categoryFilter,
      },
    ],
    async ({ queryKey }) => {
      let { categoryid } = queryKey[1];
      try {
        let res = await serviceCaller.getPurchased( categoryid );
        // console.log(">>>>>>>>>>>>>>>>>>>>>>", res.data);
        setPurchasedapiData(res.data);
      } catch (error) {
        // console.log(error);
      }
      await serviceCaller.getCategory().then((res) => setCategory(res.data));
    }
);
    
  // if (isLoading) return "Loading....";

  if (error) return "An error has occurred: " + error.message;

  // if (!purchasedapiData.docs) {
  //   return <>Loading...</>;
  // }

  return (
    <>
       <Helmet>
  <title>Purchased - AOA Dashboard</title>
  </Helmet>
      <Header />
      <div className="container">
        <SiderBar />
        <div className="container-width">
          <div className="wishlist-header">
            <div className="w-70">
              <h3> Purchased</h3>
            </div>
            <div className="wishlist-searchfield">
              <div className="purchased-filter-div">
            

<span className="producttab-center-filter-container">
                         <Filter 
                           category={category}
                          setCategoryFilter={setCategoryFilter} 
                          setCategoryName={setCategoryName}
                          />

                            </span>
              </div>
            </div>
          </div>
          <div className="min-container-wishlist pb-2">

          {purchasedapiData.docs ? (
              purchasedapiData.docs.length > 0 ? (
                purchasedapiData.docs &&
                purchasedapiData.docs.map((value) => (
                  <Wishlistcard value={value} refetch={refetch} />
                ))
              ) : (
                <>
                  <div className="product-container pb-2 ">
                    <p className="empty-content">
                      Sorry! You haven't added any
                      <span className="orange ml-2 mr-2">
                        {categoryName} category
                      </span>
                      product to Purchased.
                    </p>
                  </div>
                </>
              )
            ) : (
              <>
                <Loader />
              </>
            )}

            {/* {purchasedapiData.docs && purchasedapiData.docs.length <= 0 && 
              <>
                <div className="product-container pb-2 ">
                  <p className="empty-content">Sorry! You haven't added any product to Purchased. </p>
                </div>
              </>
            } */}

          
            {/* {purchasedapiData.docs && purchasedapiData.docs.length >= 0 ? (
              purchasedapiData.docs &&
              purchasedapiData.docs.map((value) => (
                <Wishlistcard value={value} 
                refetch={refetch}
                />
              ))
            ) : (
              <>
               
                <Loader/>
              </>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Purchased;
