import React from "react";
import { FiHome, FiAward } from "react-icons/fi";
import {
  AiOutlineStar,
  AiOutlineShoppingCart,
  AiOutlinePercentage,
  AiOutlineUser,
} from "react-icons/ai";
import { BiLogOut, BiExtension } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";

// import logo from "../assets/img/logo.png";
import logo1 from "../assets/img/logo1.png";

import { NavLink } from "react-router-dom";

function SiderBar() {
  return (
    <>
      <div className="sideBar">
        <div className="sidebar-container text-center">
          <div className="image_logo">
            <img src={logo1} className="logoImage" />
          </div>

          <div className="sidebar-lists">
            <ul className="sidebar-list">
              <li>
                <NavLink exact activeClassName="active" to="/">
                  <span className="sidebar-icon">
                    <FiHome />
                  </span>
                  home
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active" to="/wishlist">
                  <span className="sidebar-icon">
                    <AiOutlineStar />
                  </span>
                  wishlist
                </NavLink>
              </li>

              <li>
                <NavLink activeClassName="active" to="/purchased">
                  <span className="sidebar-icon">
                    <AiOutlineShoppingCart />
                  </span>
                  purchased
                </NavLink>
              </li>

              <li>
              <NavLink activeClassName="active" to="/extension">
                <span className="sidebar-icon">
                  <BiExtension />
                </span>
                extension
              </NavLink>
              </li>

              <li>
                <NavLink activeClassName="active" to="/pricing">
                  <span className="sidebar-icon">
                    <AiOutlinePercentage />
                  </span>
                  pricing
                </NavLink>
              </li>

              <li>
                <NavLink activeClassName="active" to="/learnmore">
                  <span className="sidebar-icon">
                    <BsArrowRight />
                  </span>
                  learn more
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="sidebar-logout">
            <NavLink activeClassName="active" to="/user">
              <span className="sidebar-logout-icon">
                <AiOutlineUser />
              </span>
              User
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default SiderBar;
