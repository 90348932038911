import SiderBar from "../SideBar";
import React, { useState, useRef, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "react-accessible-accordion/dist/fancy-example.css";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import "../../assets/css/pricing.css";
import Header from "../Header";
import {Helmet} from "react-helmet";
function Pricing() {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [message, setMessage] = useState("");

  const [loader, Setloader] = useState(false);

  const [changePlan, setchangePlan] = useState(true);

  const handleChange = () => {
    setchangePlan((prevState) => !prevState);
  };
  return (
    <>
    <Helmet>
  <title>Pricing - AOA Dashboard</title>
  </Helmet>
    
     <Header />
      <div className="container">
        <SiderBar />
        <div className="container-width">
          {/* <div className="wishlist-header">

<div className="w-70">
    <h3 >Pricing</h3>
</div>


</div> */}
<div className="extension-header">

<div className="w-70">
  <h3 >Pricing</h3>
</div>
<div className="wishlist-searchfield">

  

</div>

</div>
          <div className="min-container-wishlist pb-2 bg-white pr-8 pl-8">
            <section className="pricings-parent">
              {/* <div className="container">
             </div>  */}
              <h4 className="extension-subheading pt-2 pl-2 pr-2 text-center">
              We have different plans that are best suitable for all the Amazon Sellers. We provide the most profitable product deals at the lowest prices in the market. 
              </h4>
                <div className="">
                  <div className="Pricing-switch-topbar">
                    <h4>Monthly</h4>
                    <div className="pricing-toggle">
                      <label class="switch">
                        <input
                          type="checkbox"
                          value={changePlan}
                          onChange={handleChange}
                        />

                        <span class="slider round"></span>
                      </label>
                    </div>
                    <h4>Annually</h4>
                  </div>
                 
                  <table className="pricing-table-parent">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="pricing-plan-list">
                          <h4> Silver </h4>
                          <h3>
                            {changePlan ? "$49.99" : "$649"}
                            <span>
                              {changePlan ? "/Monthly" : "/Annually"}
                            </span>{" "}
                          </h3>
                          {changePlan ? (
                            <div className="tooltip tool-tip">
                              <div className="offers-text">
                                <img src="/img/offer-new.png" />
                                <p>View Offers</p>
                              </div>

                              <span className="tooltiptext">
                                $699 Free category ungating
                              </span>
                            </div>
                          ) : (
                            <div className="tooltip">
                              <div className="offers-text">
                                <img src="/img/offer-new.png" />
                                <p>View Offers</p>
                              </div>

                              <span className="tooltiptext">
                                Free 1 category Worth of $699
                              </span>
                            </div>
                          )}

                          {/* <Link
                        className="btn  custom-btn pricing-outline-button"
                        href={
                          changePlan
                            ? "https://buy.stripe.com/aEU8A3fgfc5Obrq7sI"
                            : "https://buy.stripe.com/14k7vZfgfd9SbrqaEQ"
                        }
                      > */}
                          <a
                            className="btn  custom-btn pricing-outline-button"
                            href={
                              changePlan
                                ? "https://buy.stripe.com/aEU8A3fgfc5Obrq7sI"
                                : "https://buy.stripe.com/14k7vZfgfd9SbrqaEQ"
                            }
                            target={"_blank"}
                            //   className="btn
                            //    custom-btn pricing-outline-button"
                          >
                            Buy
                          </a>
                          {/* </Link> */}
                        </th>
                        <th className="pricing-plan-list">
                          <h4>Gold </h4>
                          <h3>
                            {changePlan ? "$99.99" : "$997"}
                            <span>
                              {changePlan ? "/Monthly" : "/Annually"}
                            </span>{" "}
                          </h3>
                          {changePlan ? (
                            <div className="tooltip tool-tip">
                              <div className="offers-text">
                                <img src="/img/offer-new.png" />
                                <p>View Offers</p>
                              </div>

                              <span className="tooltiptext">
                                $699 Free category ungating
                              </span>
                            </div>
                          ) : (
                            <div className="tooltip">
                              <div className="offers-text">
                                <img src="/img/offer-new.png" />
                                <p>View Offers</p>
                              </div>
                              <span className="tooltiptext">
                                Free 1 category Worth of $699
                              </span>
                            </div>
                          )}

                          {/* <Link
                        className="btn  custom-btn pricing-outline-button"
                        href={
                          changePlan
                            ? "https://buy.stripe.com/28o3fJd874Dm532148"
                            : "https://buy.stripe.com/eVa6rV7NNgm42UU28j"
                        }
                      > */}
                          <a
                            className="btn  custom-btn pricing-outline-button"
                            href={
                              changePlan
                                ? "https://buy.stripe.com/28o3fJd874Dm532148"
                                : "https://buy.stripe.com/eVa6rV7NNgm42UU28j"
                            }
                            target={"_blank"}
                            //   className="btn  custom-btn pricing-outline-button"
                          >
                            Buy
                          </a>
                          {/* </Link> */}

                          {/* <button onClick={() => router.push(changePlan ? "https://buy.stripe.com/28o3fJd874Dm532148" : "https://buy.stripe.com/eVa6rV7NNgm42UU28j")} className="btn  custom-btn pricing-outline-button">Buy</button> */}
                        </th>
                        <th className="pricing-plan-list popular">
                          <h4>Diamond </h4>
                          <h3 className="diamond-pricing">
                            {changePlan ? (
                              <>
                                <strong
                                  style={{
                                    fontSize: "11px !important",
                                    textDecoration: "line-through",
                                    color: "#ff9900",
                                  }}
                                >
                                  $129.99
                                </strong>
                                <strong>$95</strong>
                              </>
                            ) : (
                              "$1297"
                            )}
                            <span>
                              {changePlan ? "/Monthly" : "/Annually"}{" "}
                            </span>
                          </h3>
                          {/* <p style={{fontSize:"12px" , fontWeight:"500"}}>{changePlan? "Offer valid for limited time" :""}</p> */}
                          {changePlan ? (
                            <div className="tooltip ">
                              <div className="offers-text offers-spl">
                                <img src="/img/offer-white.png" />
                                <p>View Offers</p>
                              </div>

                              <span className="tooltiptext tooltip-clr">
                                Offer valid for limited time
                              </span>
                            </div>
                          ) : (
                            <div className="tooltip">
                              <div className="offers-text offers-spl">
                                <img src="/img/offer-white.png" />
                                <p>View Offers</p>
                              </div>

                              <span className="tooltiptext tooltip-clr">
                                Free 1 brand worth of $1499
                              </span>
                            </div>
                          )}

                          {/* <p style={{ fontSize: "9px", fontWeight: "600" }}>{changePlan ? "" : "$1499  Free brand ungating"}</p> */}
                          {/* <Link
                        className="btn  custom-btn pricing-outline-button"
                        href={
                          changePlan
                            ? "https://buy.stripe.com/dR6g2v2ttgm42UU00h"
                            : "https://buy.stripe.com/dR617B8RR9XGgLKaEM"
                        }
                      > */}
                          <a
                            target={"_blank"}
                            //   className="btn  custom-btn pricing-outline-button"
                            className="btn  custom-btn pricing-outline-button"
                            href={
                              changePlan
                                ? "https://buy.stripe.com/dR6g2v2ttgm42UU00h"
                                : "https://buy.stripe.com/dR617B8RR9XGgLKaEM"
                            }
                          >
                            Buy
                          </a>
                          {/* </Link> */}
                        </th>
                        <th className="pricing-plan-list">
                          <h4>Platinum </h4>
                          <h3>
                            {changePlan ? "$149.99" : "$1399"}
                            <span>
                              {changePlan ? "/Monthly" : "/Annually"}{" "}
                            </span>
                          </h3>
                          {changePlan ? (
                            <div className="tooltip tool-tip">
                              <div className="offers-text">
                                <img src="/img/offer-new.png" />
                                <p>View Offers</p>
                              </div>

                              <span className="tooltiptext">
                                $1499 Free brand ungating
                              </span>
                            </div>
                          ) : (
                            <div className="tooltip">
                              <div className="offers-text">
                                <img src="/img/offer-new.png" />
                                <p>View Offers</p>
                              </div>
                              <span className="tooltiptext">
                                Free 1 brand worth of $1499
                              </span>
                            </div>
                          )}
                      
                          <a
                            className="btn  custom-btn pricing-outline-button"
                            href={
                              changePlan
                                ? "https://buy.stripe.com/cN2g2v7NN7PyanmaEJ"
                                : "https://buy.stripe.com/5kA6rV3xxfi03YY4gq"
                            }
                            target={"_blank"}
                            //   className="btn  custom-btn pricing-outline-button"
                          >
                            Buy
                          </a>
                          {/* </Link> */}
                        </th>
                        <th
                          className="pricing-plan-list"
                          style={{ maxwidth: "104px !important" }}
                        >
                          <h4>Pearl'5 </h4>
                          <h3>
                            $9.98<span>/Daily </span>{" "}
                          </h3>
                          {/* {changePlan? "" : <br/>}      */}
                          <p style={{ fontSize: "15px", visibility: "hidden" }}>
                            {changePlan ? "-" : "-"}
                          </p>
                      
                          <a
                            className="btn  custom-btn pricing-outline-button"
                            href={
                              changePlan
                                ? "https://buy.stripe.com/6oEdUn0ll3zi0MM5kz"
                                : "https://buy.stripe.com/6oEdUn0ll3zi0MM5kz"
                            }
                            target={"_blank"}
                            //   className="btn  custom-btn pricing-outline-button"
                          >
                            Buy
                          </a>
                          {/* </Link> */}
                        </th>
                        <th className="pricing-plan-list">
                          <h4>Titanium </h4>
                          <h3>
                            {changePlan ? "$499.99" : "$4499"}
                            <span>
                              {changePlan ? "/Monthly" : "/Annually"}{" "}
                            </span>
                          </h3>
                          {changePlan ? (
                            <div className="tooltip tool-tip">
                              <div className="offers-text">
                                <img src="/img/offer-new.png" />
                                <p>View Offers</p>
                              </div>

                              <span className="tooltiptext">
                                <p>$6500</p>
                                Free category ungating
                              </span>
                            </div>
                          ) : (
                            <div className="tooltip">
                              <div className="offers-text">
                                <img src="/img/offer-new.png" />
                                <p>View Offers</p>
                              </div>

                              <span className="tooltiptext">
                                Free 5 categories + 2 brand worth of $6500
                              </span>
                            </div>
                          )}
                         
                          <a
                            className="btn  custom-btn pricing-outline-button"
                            href={
                              changePlan
                                ? "https://buy.stripe.com/14k9E79VV8TCanm00e"
                                : "https://buy.stripe.com/14keYrecbedW7ba6ox"
                            }
                            target={"_blank"}
                            //   className="btn  custom-btn pricing-outline-button"
                          >
                            Buy
                          </a>
                      
                        </th>
                      </tr>
                    </thead>
                    <tr>
                      <td style={{ borderTop: "1px solid #bbb" }}>People</td>
                      <td>13</td>
                      <td>10</td>
                      <td>8</td>
                      <td>7</td>
                      <td>5 Purchases</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>Deals/List</td>
                      <td>7-10</td>
                      <td>7-10</td>
                      <td>5-7</td>
                      <td>7-11</td>
                      <td>5</td>
                      <td>5-7</td>
                    </tr>
                    <tr>
                      <td>ROI | Net Profit (Min)</td>
                      <td>30% | $4</td>
                      <td>30% | $4</td>
                      <td>30% | $4</td>
                      <td>30% | $5</td>
                      <td>30% | $4</td>
                      <td>30% | $4</td>
                    </tr>
                    <tr>
                      <td>Average ROI</td>
                      <td>55%</td>
                      <td>65%</td>
                      <td>40%</td>
                      <td>75%</td>
                      <td>50%</td>
                      <td>40%</td>
                    </tr>
                    <tr>
                      <td>BSR Percentage</td>
                      <td>Below 2%</td>
                      <td>Below 1.8%</td>
                      <td>Below 2%</td>
                      <td>Below 1.5%</td>
                      <td>Below 2%</td>
                      <td>Below 1.5%</td>
                    </tr>
                    <tr>
                      <td> 90 Days BSR Rank</td>
                      <td>Below 200k</td>
                      <td>Below 200k</td>
                      <td>Below 200k</td>
                      <td>Below 200k</td>
                      <td>Below 200k</td>
                      <td>Below 200k</td>
                    </tr>
                    <tr>
                      <td>Amazon competition(current)</td>
                      <td>
                        <img
                          src="/img/forbidden.png"
                          alt="wrongicon"
                          className="pricing-not-valid"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/forbidden.png"
                          alt="wrongicon"
                          className="pricing-not-valid"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/forbidden.png"
                          alt="wrongicon"
                          className="pricing-not-valid"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/forbidden.png"
                          alt="wrongicon"
                          className="pricing-not-valid"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/forbidden.png"
                          alt="wrongicon"
                          className="pricing-not-valid"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/forbidden.png"
                          alt="wrongicon"
                          className="pricing-not-valid"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>IP complaint Check</td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Gated or Ungated</td>
                      <td>Mixed</td>
                      <td>Mixed</td>
                      <td>Open</td>
                      <td>Mixed</td>
                      <td>Mixed</td>
                      <td>Mixed</td>
                    </tr>
                    <tr>
                      <td>FBA competetive sellers</td>
                      <td>Below 15</td>
                      <td>Below 10</td>
                      <td>Below 15</td>
                      <td>Below 8</td>
                      <td>Below 15</td>
                      <td>Below 10</td>
                    </tr>
                    <tr>
                      <td>Extensions</td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Number of sales (month)</td>
                      <td>100+</td>
                      <td>100+</td>
                      <td>100+</td>
                      <td>100+</td>
                      <td>100+</td>
                      <td>100+</td>
                    </tr>
                    <tr>
                      <td>Bonus Deals</td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/forbidden.png"
                          alt="wrongicon"
                          className="pricing-not-valid"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/forbidden.png"
                          alt="wrongicon"
                          className="pricing-not-valid"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3days Money Back guarantee</td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/forbidden.png"
                          alt="wrongicon"
                          className="pricing-not-valid"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>2 Level Validation</td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>24/7 Chat Support</td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                      <td>
                        <img
                          src="/img/check.png"
                          className="pricing-icon"
                          alt="checkicon"
                        />
                      </td>
                    </tr>
                  </table>
                  <div className="Activelist-parent">
                    <div className="Activelist">

                    </div>
                
                    <table>
                      <tr className="Active-table">
                        <td>Active</td>
                        <td>
                          <h6>List 2</h6>
                        
                        </td>
                        <td>
                          <h6>List 3</h6>
                        </td>
                        <td>
                          <h6>List 3</h6>
                         
                        </td>
                        <td>
                          <h6>List 4</h6>
                          </td>
                        <td>
                          <h6> Daily List</h6>
                        </td>
                        <td>
                          <h6>List 2</h6>
                        </td>
                      </tr>
                    </table>
                    <table className="" style={{ margin: "10px 0px 0px 0px" }}>
                      <tr className="Active-table">
                        <td>Sold out </td>
                        <td>
                          <button className="product-button disabled active-btn">
                            List 1
                          </button>
                        </td>
                        <td>
                          <button className="product-button disabled active-btn">
                            List 1, 2
                          </button>
                        </td>
                        <td>
                          <button className="product-button disabled active-btn">
                            List 1, 2
                          </button>
                        </td>
                        <td>
                          <button className="product-button disabled active-btn">
                            List 1, 2 , 3
                          </button>
                        </td>
                        <td></td>
                        <td>
                          <button className="product-button disabled active-btn">
                            List 1
                          </button>
                        </td>
                      </tr>
                    </table>
                 
                  </div>
                        </div>
            </section>

            <section className="pricing-page">
              <div className="container">
                <div class="pricing-parent">
                  <div class="pricing-silver">
                    <h2>Silver </h2>
                    <h3>
                      {changePlan ? "$49.99" : "$649"}
                      <span>{changePlan ? "/Monthly" : "/Annually"}</span>{" "}
                    </h3>
                    <h1>
                      {changePlan ? "" : "Free 1 category worth of  $699"}{" "}
                    </h1>
                    <h5>13 Members </h5>
                    <h5>7-10 Deals/List </h5>
                    <h5>30% Min ROI | $4+ Net Profit </h5>
                    <h5>Avg ROI 55% </h5>
                    <h5>BSR below 2%</h5>
                    <h5>90 Days BSR below 200K </h5>
                    <h5>No Amazon </h5>
                    <h5>IP claims check</h5>
                    <h5>Mixed Deals</h5>
                    <h5>FBA Competition below 15 </h5>
                    <h5>Free extensions</h5>
                    <h5>100+ Sales a Month </h5>
                    <h5>Exclusive Bonus Deals </h5>
                    <h5>3 days Money Back Guarantee </h5>
                    <h5>2 Level Validation process </h5>
                    <h5>24/7 Customer Support </h5>
                    <h6>Active List 2</h6>
                   
                    <a
                      className="product-button silverplan-btn disabled"
                      href={
                        changePlan
                          ? "https://buy.stripe.com/aEU8A3fgfc5Obrq7sI"
                          : "https://buy.stripe.com/14k7vZfgfd9SbrqaEQ"
                      }
                    >
                      <a
                        target={"_blank"}
                        className="product-button silverplan-btn disabled silver-res-btn"
                      >
                        Buy
                      </a>
                    </a>
                    <button className="product-button silverplan-btn disabled">
                      Sold Out - List 1
                    </button>
                  </div>
                  <div class="pricing-silver">
                    <h2>Gold </h2>
                    <h3>
                      {changePlan ? "$99.99" : "$997"}
                      <span>{changePlan ? "/Monthly" : "/Annually"}</span>{" "}
                    </h3>
                    <h1>
                      {changePlan ? "" : "Free 1 category worth of  $699"}{" "}
                    </h1>
                    <h5>10 Members </h5>
                    <h5>7-10 Deals/List </h5>
                    <h5>30% Min ROI | $4+ Net Profit </h5>
                    <h5>Avg ROI 65% </h5>
                    <h5>BSR below 1.8%</h5>
                    <h5>90 Days BSR below 200K </h5>
                    <h5>No Amazon </h5>
                    <h5>IP claims check</h5>
                    <h5>Mixed Deals</h5>
                    <h5>FBA Competition below 15 </h5>
                    <h5>Free extensions</h5>
                    <h5>100+ Sales a Month </h5>
                    <h5>Exclusive Bonus Deals </h5>
                    <h5>3 days Money Back Guarantee </h5>
                    <h5>2 Level Validation process </h5>
                    <h5>24/7 Customer Support </h5>
                    <h6>Active - List 3</h6>
                    <a
                      className="btn  custom-btn pricing-outline-button"
                      href={
                        changePlan
                          ? "https://buy.stripe.com/28o3fJd874Dm532148"
                          : "https://buy.stripe.com/eVa6rV7NNgm42UU28j"
                      }
                    >
                      <a
                        target={"_blank"}
                        className="product-button silverplan-btn disabled silver-res-btn"
                      >
                        Buy
                      </a>
                    </a>
                    {/* <h6> </h6> */}
                    <button className="product-button disabled silverplan-btn">
                      Sold out - List 1 , 2{" "}
                    </button>
                  </div>
                  <div class="pricing-silver">
                    <h2>Diamond </h2>
                    <h3>
                      {changePlan ? "$95" : "$1297"}
                      <span>{changePlan ? "/Monthly" : "/Annually"} </span>
                    </h3>
                    <h1>{changePlan ? "" : "Free 1 brand worth of  $1499"} </h1>
                    <h4>Most Popular</h4>
                    <h5 className="open-category">
                      Offer valid for limited time
                    </h5>
                    <h5>8 Members </h5>
                    <h5>5-7 Deals/List </h5>
                    <h5>30% Min ROI | $4+ Net Profit </h5>
                    <h5>Avg ROI 40% </h5>
                    <h5>BSR below 2%</h5>
                    <h5>90 Days BSR below 200K </h5>
                    <h5>No Amazon </h5>
                    <h5>IP claims check</h5>
                    <h5 className="open-category">Open Deals</h5>
                    <h5>FBA Competition below 15 </h5>
                    <h5>Free extensions </h5>
                    <h5>100+ Sales a Month </h5>
                    <h5>3 days Money Back Guarantee </h5>
                    <h5>2 Level Validation process </h5>
                    <h5>24/7 Chat Support </h5>
                    <h6 className="open-category"> Just Launched</h6>
                    <h6>Active - List3</h6>
                    <a
                      className="btn  custom-btn pricing-outline-button"
                      href={
                        changePlan
                          ? "https://buy.stripe.com/dR6g2v2ttgm42UU00h"
                          : "https://buy.stripe.com/dR617B8RR9XGgLKaEM"
                      }
                    >
                      <a
                        target={"_blank"}
                        className="product-button silverplan-btn disabled silver-res-btn"
                      >
                        Buy
                      </a>
                    </a>

                    {/* <h6> </h6> */}
                    <button className="product-button disabled silverplan-btn">
                      Sold Out - List 1, 2
                    </button>
                  </div>
                  <div class="pricing-silver">
                    {/* <div class="populartag">Most Popular</div> */}
                    <h2>Platinum </h2>
                    <h3>
                      {changePlan ? "$149.99" : "$1399"}
                      <span>{changePlan ? "/Monthly" : "/Annually"} </span>
                    </h3>{" "}
                    {/* <h4>Most Popular</h4> */}
                    <h1>{changePlan ? "" : "Free 1 brand worth of  $1499"} </h1>
                    <h5>7 Members </h5>
                    <h5>7-11 Deals/List </h5>
                    <h5>30% Min ROI | $5+ Net Profit </h5>
                    <h5>Avg ROI 75%</h5>
                    <h5>BSR below 1.5%</h5>
                    <h5>90 Days BSR below 200K </h5>
                    <h5>No Amazon </h5>
                    <h5>IP claims check</h5>
                    <h5>Mixed Deals</h5>
                    <h5>FBA Competition below 8</h5>
                    <h5>Free extensions </h5>
                    <h5>100+ Sales a Month </h5>
                    <h5>Exclusive Bonus Deals </h5>
                    <h5>3 days Money Back Guarantee </h5>
                    <h5>2 - Level Validation </h5>
                    <h5>24/7 Customer Support </h5>
                    <h6>Active - List 4</h6>
                    <a
                      className="btn  custom-btn pricing-outline-button"
                      href={
                        changePlan
                          ? "https://buy.stripe.com/cN2g2v7NN7PyanmaEJ"
                          : "https://buy.stripe.com/5kA6rV3xxfi03YY4gq"
                      }
                    >
                      <a
                        target={"_blank"}
                        className="product-button silverplan-btn disabled silver-res-btn"
                      >
                        Buy
                      </a>
                    </a>
                    {/* <h6> </h6> */}
                    <button className="product-button disabled silverplan-btn">
                      Sold out - List 1 , 2 , 3
                    </button>
                  </div>
                  <div class="pricing-silver">
                    <div class="populartag">Most Popular</div>

                    <h2>Pearl'5 </h2>
                    <h3>$9.98 / (Per Day)</h3>
                    <h5>5 Members </h5>
                    <h5>5 Deals/List </h5>
                    <h5>30% Min ROI | $5+ Net Profit </h5>
                    <h5>Avg ROI 50%</h5>
                    <h5>BSR below 2%</h5>
                    <h5>90 Days BSR below 200K </h5>
                    <h5>No Amazon </h5>
                    <h5>IP claims check</h5>
                    <h5>Mixed Deals</h5>
                    <h5>FBA Competition below 15</h5>
                    <h5>Free extensions </h5>
                    <h5>100+ Sales a Month </h5>
                    <h5>Exclusive Bonus Deals </h5>
                    <h5>3 days Money Back Guarantee </h5>
                    <h5>2 - Level Validation </h5>
                    <h5>24/7 Customer Support </h5>

                    <h6>Daily list</h6>
                    <a
                      className="btn  custom-btn pricing-outline-button"
                      href={
                        changePlan
                          ? "https://buy.stripe.com/6oEdUn0ll3zi0MM5kz"
                          : "https://buy.stripe.com/6oEdUn0ll3zi0MM5kz"
                      }
                    >
                      <a
                        target={"_blank"}
                        className="product-button silverplan-btn disabled silver-res-btn"
                      >
                        Buy
                      </a>
                    </a>
                   
                  </div>
                  <div class="pricing-silver">
                   
                    <h2>Titanium </h2>
                    <h3>
                      {changePlan ? "$499.99" : "$4499"}
                      <span>{changePlan ? "/Monthly" : "/Annually"} </span>
                    </h3>
                    <h1>
                      {changePlan
                        ? ""
                        : "Free 5 categories + 2 brand  worth of  $6500"}{" "}
                    </h1>
                    <h5>1 Member </h5>
                    <h5>4-6 Deals/List </h5>
                    <h5>30% Min ROI | $5+ Net Profit </h5>
                    <h5>Avg ROI 60%</h5>
                    <h5>BSR below 1.5%</h5>
                    <h5>90 Days BSR below 200K </h5>
                    <h5>No Amazon </h5>
                    <h5>IP claims check</h5>
                    <h5>Mixed Deals</h5>
                    <h5>FBA Competition below 10</h5>
                    <h5>Free extensions </h5>
                    <h5>100+ Sales a Month </h5>
                    <h5>Exclusive Bonus Deals </h5>
                    <h5>3 days Money Back Guarantee </h5>
                    <h5>2 - Level Validation </h5>
                    <h5>24/7 Customer Support </h5>
                    <h6>List 2</h6>
                    {/* <h6>Active - List 4</h6> */}
                    <a
                      className="btn  custom-btn pricing-outline-button"
                      href={
                        changePlan
                          ? "https://buy.stripe.com/14k9E79VV8TCanm00e"
                          : "https://buy.stripe.com/14keYrecbedW7ba6ox"
                      }
                    >
                      <a
                        target={"_blank"}
                        className="product-button silverplan-btn disabled silver-res-btn"
                      >
                        Buy
                      </a>
                    </a>
                  
                    <button className="product-button disabled silverplan-btn">
                      Sold out - List 1
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
export default Pricing;
