import React from "react";
import { useEffect, useState } from "react";
import * as serviceCaller from "../Services";

export default function Filter({ category, setCategoryFilter,setCategoryName }) {
  const handleFilter = (e) => {
    console.log(">>>>>>wishlistfilter", e.target.value);
    setCategoryFilter(e.target.value);
    setCategoryName(e.target[e.target.options.selectedIndex].dataset.name);
  };

  return (
    <>
      <select className="category-dropdown" onChange={handleFilter}>
        <option selected disabled>
          Default Category
        </option>
        {category.docs &&
          category.docs.map((cat) => (
            <option value={cat._id} key={cat._id} data-name={cat.name}>
              {cat.name}
            </option>
          ))}
      </select>
    </>
  );
}
