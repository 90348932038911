import React from "react";
// import productimage from "../assets/img/product.jpg";
import {
  AiOutlineShoppingCart,
  AiOutlineHeart,
  AiOutlineLike,
  AiOutlineDislike,
  AiOutlineAmazon,
  AiFillCheckCircle,
} from "react-icons/ai";
import { BsFillCartCheckFill } from "react-icons/bs";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";
import { MdUpdate } from "react-icons/md";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import * as serviceCaller from "../Services/index";
import { TiDelete } from "react-icons/ti";
import { useLocation } from "react-router-dom";
import moment from "moment";

function Wishlistcard({ value, dataApi, refetch }) {
  const location = useLocation();
  // console.log("Path", location.pathname);
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const handleAddWishlist = async () => {
    // console.log("...wishlist", value);
    let res = await serviceCaller.addWishlist(value);

    if (res.status == 200) {
      setLoading(false);
      if (res.data.message === "Removed Favorites Successfully") {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
      refetch();
    }
  };

  // add purchase
  const handlePurchased = async () => {
    let res = await serviceCaller.addPurchase(value);
    // console.log(res, "res");
    if (res.status == 200) {
      setLoading(false);
      if (res.data.message === "Removed Purchases Successfully") {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
      }
      refetch();
    }
  };

  const past24hrs = moment.utc().subtract(24, "hours").valueOf();

  // console.log("past24hrs", past24hrs);

  const past72hrs = moment.utc().subtract(72, "hours").valueOf();

  // console.log("past72hrs=", past72hrs);

  // console.log("3days before = ",new Date(past72hrs).toLocaleDateString('en-US'));

  return (
    <div className="productcard-wishlist">
      <div className="product-container pb-2">
        <div className="wishlist-product-details mt-4 pt-2 pb-2 pr-2 pl-2">
          <div className="product-image-title">
            <div className="product-image">
              <img
                src={value.image}
                alt="productimage"
                className="productimage"
              />
            </div>

            <div className="product-heading">
              <h4 className="product-title pt-1"> {value.name} </h4>
              <h5 className="category-title pt-1">
                {value.category_id.name
                  ? value.category_id.name
                  : "Uncategorized"}
              </h5>
              <div className="product-buttons mt-2">
                {new Date(past24hrs) < new Date(value.created_on) ? (
                  <button className="new-btn">
                    <span className="new-btn-span">
                      <AiFillCheckCircle />
                    </span>
                    <span className="new-btn-span">New</span>
                  </button>
                ) : (
                  " "
                )}
                {new Date(past72hrs) < new Date(value.updated_on) ? (
                  <button className="update-btn">
                    <span className="update-btn-span">
                      <MdUpdate />
                    </span>
                    <span> update</span>
                  </button>
                ) : (
                  " "
                )}
              </div>
            </div>
          </div>
          <div className="addtocart">
            {/* { ? ( */}
            <>
              {/* <button className="heart-btn" onClick={handleAddWishlist}>
                  <AiOutlineHeart />
                </button> */}
            </>
            {/* ) : ( */}
            {/* " " */}
            {/* )} */}

            {dataApi ? (
              location.pathname == "/purchased" ? (
                <button className="dislike-btn" onClick={handlePurchased}>
                  <TiDelete />
                </button>
              ) : (
                <>
                  <button className="cart-btn" onClick={handlePurchased}>
                    {value.is_purchases ? (
                      <BsFillCartCheckFill color="#ff9900" />
                    ) : (
                      <AiOutlineShoppingCart color="#ff9900" />
                    )}
                  </button>
                
                  <button className="dislike-btn" onClick={handleAddWishlist}>
                    <TiDelete />
                  </button>
                </>
              )
            ) : (
              <button className="dislike-btn" onClick={handlePurchased}>
                <TiDelete />
              </button>
            )}
            </div>
        </div>

        <div className="wishlist-product-table-info mt-4 pl-4 mr-3 mb-3">
          <div className="product-table pt-4">
            <div className="product-table-list-1">
              <h4 className="wishlist-product-table-title "> Store Name </h4>
              <h5 className="wishlist-product-table-value ">
                {" "}
                {value.store_name}
              </h5>

              <h4 className="wishlist-product-table-title ">Net Profit </h4>
              <h5 className="wishlist-product-table-value">
                {" "}
                $ {Number(value.net_profit)}
              </h5>

              <h4 className="wishlist-product-table-title ">
                EST Monthly Sale{" "}
              </h4>
              <h5 className="wishlist-product-table-value">
                {" "}
                {value.estimated_monthly_sales}
              </h5>
            </div>

            <div className="product-table-list-2">
              <h4 className="wishlist-product-table-title "> Store Price </h4>
              <h5 className="wishlist-product-table-value">
                {" "}
                ${Number(value.store_price)}
              </h5>

              <h4 className="wishlist-product-table-title ">BSR% </h4>
              <h5 className="wishlist-product-table-value">
                {Number(value.bsr)}
              </h5>

              <h4 className="product-table-title">ASIN </h4>
              <h5 className="wishlist-product-table-value"> {value.asin}</h5>
            </div>

            <div className="product-table-list-3">
              <h4 className="wishlist-product-table-title "> Amazon Price </h4>
              <h5 className="wishlist-product-table-value">
                ${Number(value.amazon_price)}
              </h5>

              <h4 className="wishlist-product-table-title ">FBA Seller </h4>
              <h5 className="wishlist-product-table-value">
                {" "}
                {Number(value.fba_seller_count)}
              </h5>

              <h4 className="wishlist-product-table-title ">90 Days Rank </h4>
              <h5 className="wishlist-product-table-value">
                {" "}
                {Number(value.ninety_days_rank)}
              </h5>
            </div>

            <div className="product-table-list-4">
              <h4 className="wishlist-product-table-title "> ROI</h4>
              <h5 className="wishlist-product-table-value">
                {" "}
                {Number(value.roi)}%
              </h5>

              <h4 className="wishlist-product-table-title ">FBM Seller </h4>
              <h5 className="wishlist-product-table-value">
                {" "}
                {Number(value.fbm_seller_count)}
              </h5>

              <h4 className="wishlist-product-table-title ">UPC/EAN </h4>
              <h5 className="wishlist-product-table-value">
                {" "}
                {Number(value.upc_ean)}
              </h5>
            </div>
          </div>
          <hr className="rulerline" />
          <h4 className="product-offer-info pb-2 pt-1">
            {" "}
            Don't forget to make use of below offers!
          </h4>

          <div className="wishlist-product-offer-table">
            <div className="wishlist-product-offer-table-list-1">
              <h4 className="wishlist-product-offer-info-title"> Notes </h4>
              <h5 className="wishlist-product-offer-info-value ">
                {value.notes}
              </h5>

              <h4 className="wishlist-product-offer-info-title">
                Promotion Code{" "}
              </h4>
              <h5 className="wishlist-product-offer-info-value">
                {" "}
                {value.promotion_code}
              </h5>
            </div>

            <div className="wishlist-product-offer-table-list-2">
              <h4 className="wishlist-product-offer-info-title">
                {" "}
                Shipping Notes{" "}
              </h4>
              <h5 className="wishlist-product-offer-info-value ">
                {" "}
                {value.shipping_notes}
              </h5>

              <h4 className="wishlist-product-offer-info-title">Hazmat </h4>
              <h5 className="wishlist-product-offer-info-value"> Yes</h5>
            </div>

            <div className="wishlist-product-offer-table-list-3">
              <div className="wishlist-store-buttons ">
                <a
                  className="amazon-btn mb-2"
                  href={value.amazon_product_url}
                  target="_blank"
                >
                  <AiOutlineAmazon /> amazon
                </a>
                <a
                  className="store-btn mb-2"
                  href={value.store_product_url}
                  target="_blank"
                >
                  <FaShoppingCart /> store
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wishlistcard;
