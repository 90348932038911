import Productcard from "./Productcard";

import { FiSearch } from "react-icons/fi";
import { useState } from "react";

import Header from "../Header";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import * as serviceCaller from "../../Services/index";
import Loader from "../Loader";

function ProductTab({
  allDeal,
  todaydeal,
  setSort,
  categoryGet,
  render,
  setSearchterm,
  setPlan,
  setPlanlist,
  refetch,
  pagesizeset,
}) {
  // const [addFilter, setAddfilter] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [category, setCategory] = useState([]);

  // tab switching
  const [activeTab, setActiveTab] = useState("todaydeal");

  //  today deal roi
  let roiarray = todaydeal.docs && todaydeal.docs.map((x) => x.roi);
  // console.log("allDealroiarray",roiarray );
  const initialValue = 0;
  let roiarrayvalue =
    roiarray?.length >= 0
      ? roiarray.reduce((value, sum) => value + sum, initialValue)
      : " ";
  // console.log("roiarrayvalue",roiarrayvalue);
  let roiarrayAverage = Math.ceil(roiarrayvalue / roiarray?.length);
  // console.log("roiarrayAverage",roiarrayAverage);

  //  today deal netprofit
  let netprofitarray =
    todaydeal.docs && todaydeal.docs.map((x) => x.net_profit);
  //  console.log("netprofitarray",netprofitarray );
  let netprofitarrayvalue =
    netprofitarray?.length >= 0
      ? netprofitarray.reduce((value, sum) => value + sum, initialValue)
      : " ";
  // console.log("netprofitarrayvalue",netprofitarrayvalue);
  let netprofitarrayAverage = Math.ceil(
    netprofitarrayvalue / netprofitarray?.length
  );
  // console.log("netprofitarrayAverage",netprofitarrayAverage)

  //  today deal estimated_monthly_sales
  let estmonthlysalesarray =
    todaydeal.docs && todaydeal.docs.map((x) => x.estimated_monthly_sales);
  //  console.log("bsrarray",estmonthlysalesarray );
  let estmonthlysalesarrayvalue =
    estmonthlysalesarray?.length >= 0
      ? estmonthlysalesarray.reduce((value, sum) => value + sum, initialValue)
      : " ";
  // console.log("bsrarrayvalue",estmonthlysalesarrayvalue);
  let estmonthlysalesarrayAverage = Math.ceil(
    estmonthlysalesarrayvalue / estmonthlysalesarray?.length
  );
  // console.log("bsrarrayAverage",estmonthlysalesarrayAverage );

  //  today deal bsrRank
  let bsrarray =
    todaydeal.docs && todaydeal.docs.map((x) => x.ninety_days_rank);
  //  console.log("bsrarray",bsrarray );
  let bsrarrayvalue =
    bsrarray?.length >= 0
      ? bsrarray.reduce((value, sum) => value + sum, initialValue)
      : " ";
  // console.log("bsrarrayvalue",bsrarrayvalue);
  let bsrarrayvalueAverage = Math.ceil(bsrarrayvalue / bsrarray?.length);
  // console.log("bsrarrayAverage",bsrarrayvalueAverage );

  // profit estimator

  let profitEstArray =
    todaydeal.docs && todaydeal.docs.map((x) => x.net_profit);
  // console.log("profitEstArray",profitEstArray);
  let profitEstarrayvalue =
    profitEstArray?.length >= 0 ? Math.max(...profitEstArray) * 10 : " ";
  // console.log("profitEstarrayvalue",profitEstarrayvalue);

  // search filter

  const handleSearch = (e) => {
    // console.log("search", e.target.value);
    setSearchterm(e.target.value);
  };

  // category filter
  const handleFilter = (e) => {
    // console.log(e);
    // console.log(">>>>>>>>>filterval", e.target.value);
    categoryGet(e.target.value);
  };

  const handleSort = (e) => {
    // setSorting(!addSorting);
    setSort(e.target.value);
  };

  let handleplanfilter = (e) => {
       setPlan(e.target[e.target.options.selectedIndex].dataset.plan);
    setPlanlist(e.target[e.target.options.selectedIndex].dataset.listid);
  };

  const { isLoading, error, data, isFetching } = useQuery(
    "getPurchasedData",
    async () => {
      // categories
      await serviceCaller.getCategory().then((res) => setCategory(res.data));
    }
  );

  if (isLoading)
    return (
      <Loader />
      // "Loading...."
    );

  if (error) return "An error has occurred: " + error.message;

  // tab switching
  const handleTab1 = () => {
    setActiveTab("todaydeal");
  };
  const handleTab2 = () => {
    setActiveTab("alldeal");
  };

  // if(apiData.docs) {
  //   return
  // }

  return (
    <>
      {/* <Topbar/> */}
      <Header />
      <div className="topbar mt-2">
        <div className="topbar-container">
          <div className="topbar-list-1 top-bg-white">
            <p className="topbar-list-items-1-title mt-2">
              What's awaiting for you today!
            </p>

            <div className="topbar-list-items-1 mt-2">
              <div className="topbar-list-items-1-div">
                <h3 className="list-item-title">ROI</h3>
                <h2 className="list-item-value">
                  {/* 250% */}
                  {roiarrayAverage ? `${roiarrayAverage}%` : "-"}
                </h2>
              </div>

              <div className="topbar-list-items-1-div">
                <h3 className="list-item-title">NET PROFIT</h3>
                <h2 className="list-item-value">
                  {netprofitarrayAverage ? `$ ${Number(netprofitarrayAverage).toFixed(2)}` : "-"}
                </h2>
              </div>

              <div className="topbar-list-items-1-div">
                <h3 className="list-item-title">Est. Monthly Sales </h3>
                <h2 className="list-item-value">
                  {/* 10K */}
                  {estmonthlysalesarrayAverage
                    ? `${estmonthlysalesarrayAverage}`
                    : "-"}
                </h2>
              </div>

              <div className="topbar-list-items-1-div">
                <h3 className="list-item-title">Rank </h3>
                <h2 className="list-item-value">
                  {bsrarrayvalueAverage ? `${bsrarrayvalueAverage}` : "-"}
                </h2>
              </div>
            </div>
          </div>

          <div className="topbar-list-2 top-bg-white">
            <p className="topbar-list-items-2-title mt-2">Profit Estimator </p>

            <div>
              <div>
                <h3 className="list-item-title pt-2">SELL 10 QUANTITY</h3>
                <h2 className="list-item-value">
                  {profitEstarrayvalue
                    ? `${
                        profitEstarrayvalue == (-Infinity || Infinity)
                          ? "-"
                          : `$  ${Number(profitEstarrayvalue).toFixed(2)}`
                      }`
                    : "-"}
                  <span className="list-item-value-span">MIN*</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="topbar-list-3 top-bg-white text-center ">
            <p className="topbar-list-items-3-title mt-6">Advertisement </p>

            <p className="topbar-list-items-3-title pt-3">AOA Extension </p>
          </div>
        </div>
      </div>

      <div className="productbar mt-2">
        <div className="productbar-container ">
          <div className="producttab-left pt-2 pb-2">
            <div className="producttab-left-deals ">
              <span
                className={activeTab === "todaydeal" ? " todaydeal-title" : " "}
                onClick={handleTab1}
              >
                Today's Deal
              </span>
              <span
                className={activeTab === "alldeal" ? "alldeal-title " : " "}
                onClick={handleTab2}
              >
                All Deal
              </span>
            </div>
          </div>
          <div className="producttab-search-center pt-2 pb-2">
            <span
            // className="category-dropdown-span"
            >
              <input
                type="text"
                placeholder="Type here to search..."
                className="input-search-box"
                onChange={handleSearch}
              />
            </span>
          </div>

          <div className="producttab-center pt-2 pb-2">
            <span className="category-dropdown-span">
              <select
                className="category-dropdown"
                // defaultValue={values}
                onChange={handleFilter}
              >
                <option selected value="">
                  Default Category
                </option>
                {category.docs &&
                  category.docs.map((cat) => (
                    <option value={cat._id} key={cat._id}>
                      {cat.name}
                    </option>
                  ))}
              </select>
            </span>
          </div>
          <div className="producttab-right pt-2 pb-2">
            <span className="category-dropdown-span">
              <select className="category-dropdown-sort" onChange={handleSort}>
                <option value="">Default Sort</option>
                {/* id , -_id  is latest product sorting*/}
                <option value="amazon_price">Amazon Price - Low to High</option>
                <option value="-amazon_price">
                  Amazon Price - High to Low
                </option>
                <option value="roi">ROI - Low to High </option>
                <option value="-roi">ROI - High to Low</option>
                <option value="store_price">Store Price - Low to High </option>
                <option value="-store_price">Store Price - High to Low</option>
                <option value="net_profit">Net Profit - Low to High </option>
                <option value="-net_profit">Net Profit - High to Low</option>
                <option value="name">A to Z</option>
                <option value="-name">Z to A</option>
              </select>
            </span>
          </div>

          <div className="producttab-end pt-2 pb-2">
            <span className="category-dropdown-span">
              <select
                className="category-dropdown-sort"
                onChange={handleplanfilter}
              >
                <option>Default Plan</option>
               

                <option data-plan="silver" data-listId="1">
                  Silver 1
                </option>
                <option data-plan="silver" data-listId="2">
                  Silver 2
                </option>
                <option data-plan="gold" data-listId="1">
                  Gold 1
                </option>
                <option data-plan="gold" data-listId="2">
                  Gold 2
                </option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <div
        className={`product-extension-container`}
        data-render={allDeal.totalDocs}
      >
        {activeTab === "todaydeal" ? (
          todaydeal.docs && !todaydeal.docs.length ? (
            <div className="product-container width-95">
              <p className="empty-content ">No products Found </p>
            </div>
          ) : (
            <>
              {todaydeal.docs &&
                todaydeal.docs.map((value) => (
                  <Productcard
                    value={value}
                    render={render}
                    refetch={refetch}
                    activeTab={activeTab}
                  />
                ))}

              {todaydeal.docs && todaydeal.docs.length >= 20 && (
                <div className="text-center">
                  <button className="loadmore-btn" onClick={pagesizeset}>
                    Load More
                  </button>
                </div>
              )}
            </>
          )
        ) : allDeal.docs && !allDeal.docs.length ? (
          <div className="product-container width-95">
            <p className="empty-content ">No products Found </p>
          </div>
        ) : (
          <>
            {allDeal.docs &&
              allDeal.docs.map((value) => (
                <Productcard value={value} render={render} refetch={refetch} />
              ))}
            {allDeal.docs && allDeal.docs.length >= 20 && (
              <div className="text-center">
                <button className="loadmore-btn" onClick={pagesizeset}>
                  Load More
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ProductTab;
