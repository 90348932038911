import React from "react";
import Day from "./Day";
import Month from "./Month";
import Week from "./Week";
import SixtyDay from "./sixtyDay";

function index({ oneDay, month, day, week }) {
  return (
    <div>
      {(() => {
        switch (day) {
          case 1:
            return <Day data={oneDay} />;
          case 7:
            return <Week data={week} />;
          case 30:
            return <Month data={month} />;
          // case 60:
          //   return <SixtyDay data={sixtyDay} />;
          default:
            return <p>Failed to Load...</p>;
        }
      })()}
    </div>
  );
}

export default index;
