import React from "react";
// import logo from "../assets/img/logo.png";
import { slide as Menu } from 'react-burger-menu';
import { NavLink } from "react-router-dom";
import logo1 from "../assets/img/logo1.png";


export default function Header(){

    return(
        <>
        <div className="responsiveheader-container"> 
        <div className="responsiveheader-wrapper">
           <div className="responsiveheader-item">
          
           
            <img src={logo1} className="logoresponsiveImage" />
          
          </div>
            <div className="responsive__menuitem">
            <Menu Left>
        
          <ul className="mobile-menuitems">
            <li className="mobile-menuitem">
              {/* <a href="/">Home</a> */}
              <NavLink exact activeClassName="active" to="/">
Home
                </NavLink>

            </li>
            <li className="mobile-menuitem">
              {/* <a href="/wishlist">Wishlist</a> */}
              <NavLink activeClassName="active" to="/wishlist">
                  {/* <span className="sidebar-icon">
                    <AiOutlineStar />
                  </span> */}
                  wishlist
                </NavLink>
            </li>

            <li className="mobile-menuitem">
        
              {/* <a href="/purchased">Purchased</a> */}
              <NavLink activeClassName="active" to="/purchased">
                  {/* <span className="sidebar-icon">
                    <AiOutlineShoppingCart />
                  </span> */}
                  purchased
                </NavLink>
        
            </li>

            <li className="mobile-menuitem">
              {/* <a href="/extension">Extension</a> */}
              <NavLink activeClassName="active" to="/extension">
                  {/* <span className="sidebar-icon">
                    <BiExtension />
                  </span> */}
                  extension
                </NavLink>
            </li>
            <li className="mobile-menuitem">
            
              {/* <a href="/pricing">Pricing</a> */}
              <NavLink activeClassName="active" to="/pricing">
                  {/* <span className="sidebar-icon">
                    <AiOutlinePercentage />
                  </span> */}
                  pricing
                </NavLink>
            </li>
            <li className="mobile-menuitem">
              {/* <a href="/learnmore">Learnmore</a> */}
              <NavLink activeClassName="active" to="/learnmore">
                  {/* <span className="sidebar-icon">
                    <BsArrowRight />
                  </span> */}
                  learn more
                </NavLink>

            </li>
            <li className="mobile-menuitem">
              {/* <a href="/user">User</a> */}
              <NavLink activeClassName="active" to="/user">
              {/* <span className="sidebar-logout-icon">
                <AiOutlineUser />
              </span> */}
              User
            </NavLink>
            </li>
          </ul>
       
        </Menu>
        </div>
        </div>
        </div>
        </>

    );

}